* {
  box-sizing: border-box;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  min-height: 100vh;

}

/* ========================Header============================================ */

header {
  display: flex;
  justify-content: space-around;
  height: 45px;
box-shadow: 0px 5px 10px 1px #0000002e;
}

.header-name {
  font-family: 'ZCOOL QingKe HuangYou', cursive;
  color: rgb(0, 30, 108);
  display: flex;
  width: 75%;
}
.header-name-background {
  padding: 3px;
}

.header-nav {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.header-link {
  font-size: 1.3rem;
  color: rgb(0, 30, 108);
  font-family: 'ZCOOL QingKe HuangYou', cursive;
  transition: 200ms;
}
.header-link:hover {
  font-size: 1.5rem;
  text-decoration: underline;
}
.header-link-title {
  font-size: 2.4rem;
  color: rgb(0, 30, 108);
  font-family: 'ZCOOL QingKe HuangYou', cursive;
  transition: 300ms;
}

.header-link-title:hover{
  color: rgb(255, 171, 76)
}


.header-social {
  color: rgb(0, 30, 108);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.icon {
  margin: 0px 15px;
  transition: 200ms;
}
.icon:hover {
  transform: rotate(-35deg);
}

.icon-text {
  font-size: 1.1rem;
  color: rgb(0, 30, 108);
  font-family: 'ZCOOL QingKe HuangYou', cursive;
  text-decoration: none;
}

/* ===========================home page========================================= */

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-header {
  border-bottom: 1px solid rgba(0, 31, 108, 0.366);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.sky {
  background-color: rgba(80, 137, 198, 0.249);
  width: 100%;
  height: 77%;
  z-index: -2;
}

.land {
  background-color: rgba(255, 171, 76, 0.366);
  width: 100%;
  height: 23%;
  z-index: -2;
}

.profile-image {
  clip-path: circle(40%);
  position: absolute;
  top: 35px;
  left: 55%;
}

.profile-image-outline {
  clip-path: circle();
  background-color: rgba(18, 21, 106, 0.092);
  height: 320px;
  width: 240px;
  position: absolute;
  top: 35px;
  left: 55%;
  z-index: -1;
}

.home-header-text {
display: flex;
flex-direction: column;
padding: 10px;
width: 45%;
position: absolute;
top: 75px;
left: 9%;
font-size: 1.5rem;
  font-family: 'ZCOOL QingKe HuangYou', cursive;
  color: rgba(128, 128, 128, 0.619);
}


.important {
  color: rgba(255, 171, 76, 0.667);
}

.home-main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tech-skills {
  margin: 2rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tech-skills-title {
  color: rgb(0, 30, 108);
  font-size: 1.9rem;
  font-family: 'ZCOOL QingKe HuangYou', cursive;
  margin: 2rem;
}

.tech-skills-body {
  color: rgb(0, 18, 62);
  font-size: 1.5rem;
  font-family: 'ZCOOL QingKe HuangYou', cursive;
  text-align: center;
  margin: 10px;
  line-height: 1.8;
}

/* ===============================project page================================ */

.projects-header {

  border-bottom: 1px solid rgba(0, 0, 0, 0.256);
  background-color: rgba(255, 171, 76, 0.366);
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 200px;
}

.projects-heading {
  font-size: 5rem;
  color: rgba(36, 73, 113, 0.866);
  font-family: 'Oleo Script', cursive;
  text-decoration: underline;
}

.projects-header-image-container {
  height: 100%;
  padding-top: 10px;
}

.projects-header-image {
  height: 180px;
  width: auto;
}

.project-section {
  display: flex;
  padding: 60px 100px;
  justify-content: space-evenly;
}

.project-section-image {
  height: auto;
  width: 230px;
  object-fit: fill;
  margin-right: 20px;
}

.project-section-body {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 15px;
}

.project-section-title {
  font-size: 1.9rem;
  color: rgb(33, 92, 155);
  font-family: 'Oleo Script', cursive;
}

.project-section-body-links {
  display: flex;
}

.project-section-body-link {
  margin: 0px 10px;
  margin-bottom: 10px;
  padding: 5px;
  font-family: 'ZCOOL QingKe HuangYou', cursive;
  color: rgb(50, 27, 1);
  border: 1px solid rgb(33, 92, 155);
  background-color: rgba(80, 137, 198, 0.249);
  transition: 400ms;

}

.project-section-body-link:hover {
  background-color: rgb(33, 92, 155);
  color: rgb(255, 171, 76);
}

a {
  text-decoration: none;
}

.project-section-body-description {
  color: rgba(0, 0, 0, 0.599);
  font-size: 1.4rem;
  font-family: 'ZCOOL QingKe HuangYou', cursive;
  line-height: 1.2;
}

/* ==========================contact page================================== */

.contact-header {

  border-bottom: 1px solid rgba(0, 0, 0, 0.256);
  background-color: rgba(255, 171, 76, 0.366);
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 200px;
}

.contact-heading {
  font-size: 5rem;
  color: rgba(36, 73, 113, 0.866);
  font-family: 'Oleo Script', cursive;
  text-decoration: underline;
}

.contact-header-image-container {
  height: 100%;
  padding-top: 10px;
}

.contact-header-image {
  height: 150px;
  width: auto;
}

/* ===============================about=================================== */

.about-header {

  border-bottom: 1px solid rgba(0, 0, 0, 0.256);
  background-color: rgba(80, 137, 198, 0.249);
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 200px;
}

.about-heading {
  font-size: 5rem;
  color: rgba(36, 73, 113, 0.866);
  font-family: 'Oleo Script', cursive;
  text-decoration: underline;
}

.about-header-image-container {
  height: 100%;
  display: flex;
}

.about-header-image {
  height: auto;
  width: 335px;
}

.about-content-section {
  display: flex;
  padding: 30px;
  justify-content: space-evenly;
  
}

.about-section-image {
  height: 200px;
  width: auto;
}
.about-section-image2 {
  height: auto;
  width: 200px;
}
.about-section-image3 {
  height: 200px;
  width: 200px;
}
.about-section-text {
  margin: 10px;
  text-align: center;
  width: 45%;
  color: rgba(0, 0, 0, 0.599);
  font-size: 1.4rem;
  font-family: 'ZCOOL QingKe HuangYou', cursive;
  line-height: 1.2;
}

/* =============================contact===================================== */

.contact-greeting {
  display: flex;
  justify-content: center;
  margin: 50px;
  font-size: 2rem;
  color: rgba(36, 73, 113, 0.9);
  font-family: 'Oleo Script', cursive;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

.field {
 font-size: 2rem;
}

input[type='text'] {

  font-size: 1.6rem;
  margin: 15px 0px;
  font-family: 'ZCOOL QingKe HuangYou', cursive;
}

textarea {
  font-size: 1.6rem;
  margin-top: 10px;
  font-family: 'ZCOOL QingKe HuangYou', cursive;
}

input[type='submit'] {
  border: 1px solid black;
  background-color: white;
  font-size: 1.6rem;
  margin-top: 10px;
  font-family: 'ZCOOL QingKe HuangYou', cursive;
  transition: 700ms;
}

input[type='submit']:hover {
  color: white;
  background-color: black;
}


/* =============================footer===================================== */

.copyright-container {
  height: 2rem;
  margin-top: 10rem;
  border-top: 1px solid rgba(143, 147, 157, 0.708);
}

.copyright {
  color: rgba(141, 141, 142, 0.845);
  text-align: center;
  margin-top: 5px;

}

/* ipad and small screen =============================================== */

@media only screen and (max-width: 680px) {


  .header-link-title {
    font-size: 2rem;
  }



}

@media only screen and (max-width: 610px) {


  .header-link-title {
    font-size: 1.7rem;
  }
  
  .header-name {
    width: 75%;
  }
  
  .header-nav {
    width: 75%;
  }
  
  .header-link {
    font-size: 1rem;
  }

  .header-social {
    width: 75%;
  }
  
  .icon-text {
    font-size: .9rem;
  }


}
/* ==============================mobile response================================================== */

@media only screen and (max-width: 480px) {

  .header-name-background {
    padding-top: 10px;
    padding-left: 7px;
    /* border: 1px solid red; */
  }

  
  .header-link {
    font-size: 1rem;
    color: rgb(0, 30, 108);
    font-family: 'ZCOOL QingKe HuangYou', cursive;
    transition: 200ms;
  }
  .header-link:hover {
    font-size: 1.2rem;
    text-decoration: underline;
  }
  .header-link-title {
    font-size: 1.2rem;
  }
  
  
  .header-social {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .icon {
    margin: 0px 10px;
    transition: 200ms;
  }
  .icon:hover {
    transform: rotate(-35deg);
  }
  
  .icon-text {
    font-size: .9rem;
  }

  
  .sky {
    position: absolute;
    background-color: rgba(80, 137, 198, 0.249);
    width: 100%;
    height: 231px;
    top: 45px;
    z-index: -2;
  }
  
  .land {
    position: absolute;
    background-color: rgba(255, 171, 76, 0.366);
    width: 100%;
    height: 68px;
    top: 276px;
    z-index: -2;
  }
  
  .home-header {
    border-bottom: 1px solid rgba(0, 31, 108, 0.366);
    display: flex;
    flex-direction: column;
    height: 300px;
    overflow-y: scroll;
  }

  .img-container {
    height: 230px;
    width: 230px;
    display: flex;
    justify-content: center;
    margin-top: 390px;
    position: relative;
  }

  .home-header-text {
    height: 750px;
    width: 75%;
    display: flex;
    justify-content: center;
    margin-top: 75px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    position: static;
  }


  .profile-image {
    clip-path: circle(100%);
    height: 230px;
    width: 230px;
    position: static;
    border-radius: 50%;
    object-fit: cover;
    
  }

  .profile-image-outline {
    clip-path: circle(100%);
    background-color: rgba(18, 21, 106, 0.092);
    height: 245px;
    width: 245px;
    top: -3%;
    left: -3%;
    z-index: -1;
    border-radius: 50%;
  }

/* mobile projects seciton================================= */

.projects-heading {
  font-size: 4rem;
}

.projects-header-image-container {
  padding-top: 25px;
}

.projects-header-image {
  height: 150px;
}

.project-section {
  flex-direction: column;
  padding: 40px 0px;
  align-items: center;
}

.project-section-image {
  margin-left: 30px;
}

.project-section-body {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 15px;
}

.project-section-title {
  margin-bottom: 20px;
}

.project-section-body-links {
  margin-bottom: 10px;
}


.project-section-body-description {
  padding: 10px;
}

/* mobile about section ===================================== */
.about-header {
  justify-content: center;
}

.about-heading {
  font-size: 4rem;
}

.about-header-image-container {
  height: 100%;
  display: flex;
}

.about-header-image {
  margin-left: 10px;
  height: 170px;
  width: 220px;
}

.about-content-section {
  flex-direction: column;
  align-items: center;
  padding: 20px;
  justify-content: space-evenly;
  
}

.about-section-image {
  height: 250px;
  width: auto;
  object-fit: cover;
  
}
.about-section-image2 {
  height: 260px;
  width: 260px;
  object-fit: cover;
}
.about-section-image3 {
  height: 200px;
  width: 250px;
}
.about-section-image4 {
  height: auto;
  width: 220px;
}
.about-section-text {
  margin: 10px;
  text-align: center;
  width: 90%;
  color: rgba(0, 0, 0, 0.599);
  font-size: 1.4rem;
  font-family: 'ZCOOL QingKe HuangYou', cursive;
  line-height: 1.2;
  order: 99;
}

/* mobile contact section ================================ */


.contact-heading {
  font-size: 4rem;
}


.contact-header-image {
  height: 70px;
  width: auto;
  align-self: center;
  margin-top: 35%;
}


}

/* ======================very small mobile=================================== */

@media only screen and (max-width: 400px) {

  .header-name-background {
    padding-top: 3px;
    padding-left: 13px;
    /* border: 1px solid red; */
  }
  
  .header-link {
    font-size: .8rem;
    color: rgb(0, 30, 108);
    font-family: 'ZCOOL QingKe HuangYou', cursive;
    transition: 200ms;
    margin: 5px;
  }
  .header-link:hover {
    font-size: 1.2rem;
    text-decoration: underline;
  }
  .header-link-title {
    font-size: 1.2rem;
  }
  
  
  .header-social {
    width: 75%;
    padding-left: 1px;
  }
  
  .icon-text {
    font-size: .75rem;
  }

  .home-header-text {
    /* border: 1px solid red; */
    height: 750px;
    width: 300px;
    display: flex;
    justify-content: center;
    margin-top: 55px;
  }

  
  .about-heading {
    font-size: 3.5rem;
  }
  
  .about-header-image-container {
    height: 100%;
    display: flex;
  }
  
  .about-header-image {
    margin-left: 10px;
    height: 150px;
    width: 200px;
  }
  
  .about-content-section {
    flex-direction: column;
    align-items: center;
    padding: 20px;
    justify-content: space-evenly;
    
  }
  
  .about-section-image {
    height: 250px;
    width: auto;
    object-fit: cover;
    
  }
  .about-section-image2 {
    height: 260px;
    width: 260px;
    object-fit: cover;
  }
  .about-section-image3 {
    height: 200px;
    width: 250px;
  }
  .about-section-image4 {
    height: auto;
    width: 220px;
  }
  .about-section-text {
    margin: 10px;
    text-align: center;
    width: 90%;
    color: rgba(0, 0, 0, 0.599);
    font-size: 1.4rem;
    font-family: 'ZCOOL QingKe HuangYou', cursive;
    line-height: 1.2;
    order: 99;
  }

  


}





